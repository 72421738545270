<template>
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" :isLarge="false" ref="form">
        <template v-slot:title>Reject Reason</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="row">
                <div class="col-sm-12">
                    <bs-textarea v-model="message" />
                </div>
            </div>
            
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-danger" @click="reject"><i class="bi bi-x-circle"></i> Reject</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import ReviewInvoiceVendorService from "@/services/review-invoice-vendor-service.js";
export default {
    data: function () {
        return {
            id: null,
            message: null,
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
    },
    methods: {
        onPopupClosed() {
            this.$router.push(`/review-invoice-vendor/detail/${this.id}`);
        },
        close() {
            this.$refs.form.closePopup();
        },
        async reject() {
            try {
                this.$store.commit('progressCounterQueueIncrement');
                
                const response = await ReviewInvoiceVendorService.reject(this.id, this.message);
                if (!response.status) {
                    this.errorMessages = response.errorMessages;
                }
                else {
                    this.errorMessages = null;
                    this.close();
                }
            }
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
    }
}
</script>
